<template>
  <div>
    <div class="box">
      <inhead></inhead>
      <div style="position: fixed;right: 20px;bottom: 20px;height: 240px;width: 230px;" v-if="show">
        <div style="width: 100%;display: flex;flex-direction: row-reverse;padding-right: 10px;"><p class="cccc"
                                                                                                   @click="show=false">
          X</p></div>
        <div style="width: 90%;height:85%;margin: 0 auto">
          <img :src="`${website_info.website_info[0].adv_img}?time+${Math.random()}`" alt="" width="100%"
               height="100%"
               style="border: 1px solid rgba(0,0,0,0.4);box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.4);border-radius: 9px;cursor: pointer;"
               @click="guanggao">
        </div>
      </div>

      <div class="navigation"></div>
      <div class="title_box"><img :src="title" alt="" width="100%" height="100%"></div>

      <div style="display: flex;flex-wrap: wrap;justify-content: center;  margin-top: 5%;flex-wrap: nowrap">

        <div>
          <div class="ps_button_box">
            <div>
              <div class="icon_ps"><img :src="icon_ps" alt="" width="100%"></div>
              <div class="ps_text">一键做图</div>
            </div>
            <a class="ps_button" @click="r">立即作图</a>
          </div>
        </div>

        <div style="margin-left: 10px">
          <div class="ps_button_box">
            <div>
              <div class="icon_ps"><img :src="selectCurr" alt="" width="100%"></div>
              <div class="ps_text">战绩查询</div>
            </div>
            <a class="ps_button" @click="s">立即查询</a>
          </div>
        </div>

        <div style="margin-left: 10px">

          <div class="ps_button_box">
            <div>
              <div class="icon_ps"><img :src="Vac" alt="" width="100%"></div>
              <div class="ps_text">封禁查询</div>
            </div>
            <a class="ps_button" @click="vacFinder">立即查询</a>
          </div>

        </div>

        <div style="margin-left: 10px" v-if="isPhone">
          <div class="ps_button_box">
            <div>
              <div class="icon_ps"><img :src="selectCurrBuy" alt="" width="100%"></div>
              <div class="ps_text">皮肤号购买</div>
            </div>
            <a class="ps_button" target="_blank" href="https://a202202232242409930001955.szwego.com/static/index.html#/shop_detail/A202202232242409930001955">点击进入</a>
          </div>
        </div>

      </div>


      <div style="margin-left: 10px" v-if="!isPhone">
        <div>
          <a class="ps_button_phone" target="_blank"   href="https://a202202232242409930001955.szwego.com/static/index.html#/shop_detail/A202202232242409930001955">皮肤号购买</a>
        </div>
      </div>


    </div>
    <infoot></infoot>


  </div>
</template>

<script>
import infoot from './infoot'
import inhead from "./inhead";
import {mapState} from "vuex";

export default {
  components: {
    infoot,
    inhead
  },
  name: "HomePage",
  data() {
    return {
      show: true,
      isPhone: false,
      title: require('@/assets/title.png'),
      icon_ps: require('@/assets/icon_pc.png'),
      selectCurr: require('@/assets/selectCurr.png'),
      selectCurrBuy: require('@/assets/selectCurrYsStore.png'),
      Vac: require('@/assets/Vac.png')

    }
  },
  computed: {
    ...mapState('footer', ['website_info'])
  },
  methods: {
    vacFinder() {
      const alink = document.createElement('a')
      alink.href = '/vacFinder.html'
      // alink.download = steamid
      alink.click()
    },
    r() {
      this.$router.push('/getsteamid')
    },
    s() {
      const alink = document.createElement('a')
      alink.href = '/index.html'
      // alink.download = steamid
      alink.click()
    },

    openType(type, code, msg) {
      this.$notification[type]({
        message: 'code: ' + code,
        description:
        msg,
      });
    },
    guanggao() {
      const a = document.createElement('a')
      a.href = this.website_info.website_info[0].adv_url
      // alink.download = steamid
      a.click()
    },


  },
  created() {
    if (sessionStorage.getItem('wasLogin') == 1) {
      sessionStorage.removeItem('wasLogin')
      this.$router.go(0)
    }

    this.show = !(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
    this.isPhone = !(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.8'
  },

}
</script>

<style scoped>

.cccc {
  transition: all 0.5s;
  color: red;
}

.cccc:hover {
  cursor: pointer;
  transform: rotate(360deg) scale(1.2);
  color: #00b7ee;
}

a {
  text-decoration: none
}

@media screen and (min-width: 769px ) {


  .friendCon li {
    display: inline-block;
    margin-left: 10px;
    font-size: 15px;
    color: #9f9d9d

  }

  .box {
    width: 100%;
    height: 100vh;
    background: url("~@/assets/localside.jpg");
    background-size: cover;
    background-position: center;
  }

  .navigation {
    width: 100%;
    height: 48px;
  }

  .title_box {
    display: flex;
    margin: 4% auto 0;

    width: 49%;
  }

  .ps_button_box {
    margin-left: 20px;
    width: 260px;
    background: rgba(11, 12, 12, 0.26);
    border: 1px solid rgba(255, 255, 266, 0.26);
    border-radius: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .icon_ps {
    width: 100%;
    margin-top: 28%;
  }

  .ps_text {
    width: 150px;
    height: 29px;
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    margin-top: 19px;
    margin-bottom: 40%;
    text-align: center;
  }

  .ps_button {
    width: 208px;
    height: 55px;
    font-size: 30px;
    color: #fff;
    font-weight: 400;
    background: #0EA9F5;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 37px;
  }
}

.ps_button_phone{
  width: 180px;
  height: 45px;
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  background: linear-gradient(90deg, #eecf0d 0%, #fc0d0d 100%);
  margin: 20px auto 0;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .box {
    width: 100%;
    height: 100vh;
    background: url("~@/assets/localside.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .navigation {
    width: 100%;
    height: 48px;
  }

  .title_box {
    display: flex;
    margin: 5% auto 0;
    /*margin-top: 125px;*/
    /*width: 934px;*/
    /*height: 79px;*/
    width: 80%;

  }

  .ps_button_box {
    width: 100%;
    background: rgba(11, 12, 12, 0.26);
    border: 1px solid rgba(255, 255, 266, 0.26);
    border-radius: 21px;
    margin: 15% auto 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .icon_ps {
    width: 50%;
    /*width: 128px;*/
    /*height: 128px;*/
    /*margin-top:54px ;*/
    margin: 40% auto 0;
  }

  .ps_text {
    width: 120px;
    height: 35px;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    margin-top: 19px;
    /*margin-bottom: 55px;*/
    margin-bottom: 3%;
    text-align: center;
  }

  .ps_button {
    width: 85%;
    height: 50px;
    font-size: 19px;
    color: #fff;
    font-weight: 400;
    background: #0EA9F5;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

  }


  .friendCon li {
    display: inline-block;
    margin-left: 10px;
    font-size: 10px;
    color: #9f9d9d
  }

}
</style>
